import React, { useState, useEffect } from 'react';
import { Form as FormikForm, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { ButtonContainer, Button } from '../Button';
import { FieldFactory } from './Inputs';
import SocialIcons, { SocialItemsData } from '../../components/SocialIcons';
import Link from '../Link';
import RichText from '../RichText';
import { useCookie } from 'react-use';

const FormMessage = ({
  wasSuccessful,
  successTitle,
  successSubtitle,
  failureTitle,
  failureSubtitle,
}) => {
  return (
      <div className="form__message">
        <div className="form__message-content">
          <p className="form__message-title">
            {wasSuccessful ? successTitle : failureTitle}
          </p>
          <p className="form__message-subtitle">
            {wasSuccessful ? successSubtitle : failureSubtitle}
          </p>
          <p className="form__message-footer">
          Need help? Have a question? <Link href="https://workforce.pwc.com/contact">Contact us</Link>.
        </p>
        </div>
        <div className="form__message-social">
          <p>Let's connect</p>
          <SocialIcons
              items={SocialItemsData.filter((item) =>
                  [ 'twitter', 'linkedin' ].includes(item.slug),
              )}
              theme="light"
          />
        </div>
      </div>
  );
};

FormMessage.defaultProps = {
  wasSuccessful: true,
  successTitle: 'Thank you!',
  failureTitle: 'We\'re sorry',
  successSubtitle:
      'We look forward to meeting you.',
  failureSubtitle:
      'Something went wrong. Refresh the page or contact us directly via email at us_workforce_pm@pwc.com.',
};

const FormHeader = ({ title, subtitle }) => {
  return (
    <div className="form__header">
      <p className="form__title">{title}</p>
      <p className="form__subtitle">{subtitle}</p>
    </div>
  );
};

const FormRedirect = () => {
  return (
      <div className="form__message">
        <div className="form__message-content">
          <h4>
            Form successfully submitted. Redirecting...
          </h4>
        </div>
      </div>
  );
};

const encodeFormData = (values) => {
  return Object.keys(values)
               .map(
                   (key) => encodeURIComponent(key) + '=' + encodeURIComponent(values[key]),
               )
               .join('&');
};

const Form = (props) => {
  const {
    url,
    formTitle,
    formSubtitle,
    fields,
    legal,
    onSubmitSuccess,
    onSubmitFail,
    onSubmitError,
    eloquaSiteId,
    eloquaFormName,
  } = props;

  const [ value, updateCookie, deleteCookie ] = useCookie('ELOQUA');

  useEffect(() => {
    const initialElqGUIDValue = value;
    const elqGetGUIDValue = (subKey) => {
      if (initialElqGUIDValue == null || initialElqGUIDValue == '')
        return '';
      var cookieGUIDValue = initialElqGUIDValue.split('&');
      for (var i = 0; i < cookieGUIDValue.length; i++) {
        var pair = cookieGUIDValue[i].split('=');
        if (pair.length > 1) {
          if (pair[0] == subKey) {
            return pair[1];
          }
        }
      }
      return '';
    };
    const elqGUIDValue = elqGetGUIDValue('GUID');
    if (localStorage.elqCustomerGUID === null || !localStorage.elqCustomerGUID) {
      localStorage.setItem('elqCustomerGUID', elqGUIDValue);
    }
  }, []);
  const fieldValues = () => {
    let fields = {};
    if (typeof window !== 'undefined' && localStorage.utm_medium) {
      fields.utm_medium = localStorage.utm_medium;
    }
    if (typeof window !== 'undefined' && localStorage.utm_source) {
      fields.utm_source = localStorage.utm_source;
    }
    if (typeof window !== 'undefined' && localStorage.utm_campaign) {
      fields.utm_campaign = localStorage.utm_campaign;
    }
    if (typeof window !== 'undefined' && localStorage.utm_offer) {
      fields.utm_offer = localStorage.utm_offer;
    }
    if (typeof window !== 'undefined' && localStorage.utm_term) {
      fields.utm_term = localStorage.utm_term;
    }
    if (typeof window !== 'undefined' && localStorage.utm_term) {
      fields.utm_term = localStorage.utm_term;
    }
    if (typeof window !== 'undefined' && localStorage.elqCustomerGUID) {
      fields.elqCustomerGUID = localStorage.elqCustomerGUID;
    }
    if (typeof window !== 'undefined' && localStorage.cid) {
      fields.cid = localStorage.cid;
    }
    return fields;
  };

  const [ formStatus, setFormStatus ] = useState({
    submitted: false,
    success: false,
  });

  const eloquaHiddenFields = {
    elqFormName: eloquaFormName,
    elqSiteId: eloquaSiteId,
    elqCampaignId: null,
    elqCustomerGUID: '',
    elqCookieWrite: 0,
  };

  const initialValues = Object.fromEntries(
      fields.map(({ name, initialValue }) => [
        name,
        initialValue ? initialValue : '',
      ]),
  );

  const selectErrorMessage = 'Please select a valid option';

  const schemaFactory = ({
    type,
    required,
    options,
    conditionalOptions,
    conditionalFieldName,
    conditional,
  }) => {
    let schema = '';
    // First, assume everything is a string.
    let inputType = Yup.string();
    schema = inputType;

    // Next, if it's a select or radio make sure it only includes its options
    if (type === 'email') {
      schema = schema.email('Please enter a valid or business email address').matches(
          /\@(?!(me|abc|ABC|QQ|123|qq|mac|icloud|gmail|googlemail|hotmail|live|msn|outlook|yahoo|ymail|aol)\.)/, 'Please enter a business email address',
      );
    }

    // If it has options, make sure it only allows those options
    if (options) {
      schema = schema.oneOf(options, selectErrorMessage);
    }

    // if it's a conditional field
    // set up the schema to check the related field and require it
    // otherwise, require it normally.
    if (conditional) {
      schema = schema.when(conditionalFieldName, {
        is: (a) => conditionalOptions.includes(a),
        then: Yup.string().required(),
      });
    } else if (required) {
      return ( schema = schema.required('Required') );
    }

    // return our completed schema
    return schema;
  };

  const validationSchemaShape = Object.fromEntries(
      fields.map((field) => {
        return [ field.name, schemaFactory(field) ];
      }),
  );

  // Submit handler
  const handleSubmit = (values, { setSubmitting }) => {
    const encodedValues = encodeFormData(values);
    setSubmitting(true);

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodedValues,
    })
        .then((res) => {
          if (res.ok) {
            setFormStatus({ submitted: true, success: true });
            onSubmitSuccess();
          } else {
            setFormStatus({ submitted: true, success: false });
            onSubmitFail();
          }
        })
        .catch((err) => {
          setFormStatus({ submitted: true, success: false });
          onSubmitError(err);
        });
  };
  const fieldValuesOverride = fieldValues();
  const formikProps = {
    validationSchema: Yup.object().shape(validationSchemaShape),
    initialValues: { ...initialValues, ...eloquaHiddenFields, ...fieldValuesOverride },
    onSubmit: handleSubmit,
  };

  return (
      <div className="form">
        {formStatus.submitted ? (
            <FormRedirect wasSuccessful={formStatus.success}/>
        ) : (
            <>
              <FormHeader title={formTitle} subtitle={formSubtitle}/>
              <div className="form__body">
                <Formik
                    {...formikProps}
                >
                  {(props) => {
                    const { isSubmitting, values } = props;
                    const submitDisabled = !( props.isValid && props.dirty );
                    return (
                        <FormikForm
                            name={eloquaFormName}
                            className={`form__element ${
                                isSubmitting ? 'form__element--submitting' : ''
                            }`}
                        >
                          <div className="form__inputs">
                            {fields.map((field, index) => {
                              let isDisabled =
                                  field.conditional &&
                                  !field.conditionalOptions.includes(
                                      values[field.conditionalFieldName],
                                  );

                              return (
                                  <FieldFactory
                                      key={index}
                                      disabled={isDisabled}
                                      {...field}
                                  />
                              );
                            })}
                          </div>

                          {/* {legal?.json && (
                      <div className="form__legal">
                        <RichText source={legal.json} />
                      </div>
                    )} */}
                          <ButtonContainer align="center">
                            <Button
                                id="pe-form-submit"
                                disabled={submitDisabled || isSubmitting}
                                type="submit"
                            >
                              {isSubmitting ? 'Submitting...' : 'Submit'}
                            </Button>
                          </ButtonContainer>
                        </FormikForm>
                    );
                  }}
                </Formik>
              </div>
            </>
        )}
      </div>
  );
};
Form.defaultProps = {
  fields: [],
  onSubmitSuccess: () => null,
  onSubmitError: () => null,
  onSubmitFail: () => null,
};

export { Form, encodeFormData };
